import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1000 1000" width={40} height={40} {...props}>
      <path
        style={{ opacity: 0.992 }}
        fill="#37b4e1"
        d="M 609.5,146.5 C 510.949,174.137 412.282,174.471 313.5,147.5C 292.492,140.995 273.159,131.162 255.5,118C 230.799,95.9468 230.466,73.6135 254.5,51C 280.875,31.8204 310.209,19.1537 342.5,13C 422.907,-3.93625 503.24,-3.60292 583.5,14C 608.156,19.6616 631.49,28.6616 653.5,41C 663.59,47.1249 672.09,54.9582 679,64.5C 689.016,84.9453 685.182,102.445 667.5,117C 650.132,130.524 630.799,140.357 609.5,146.5 Z M 520.5,98.5 C 480.835,87.8335 441.168,87.8335 401.5,98.5C 381.871,96.7598 363.204,91.5931 345.5,83C 331.272,74.7107 330.939,66.044 344.5,57C 356.123,50.681 368.456,46.3476 381.5,44C 432.771,34.4162 484.104,34.0829 535.5,43C 547.206,45.1248 558.539,48.4581 569.5,53C 576.184,55.6937 581.684,59.8604 586,65.5C 587.573,72.1925 585.406,77.3592 579.5,81C 561.103,90.9643 541.436,96.7976 520.5,98.5 Z"
      />
      <path
        style={{ opacity: 0.963 }}
        fill="#f7b81d"
        d="M 520.5,98.5 C 516.434,100.018 512.101,100.851 507.5,101C 478.524,103.747 449.524,104.081 420.5,102C 413.938,101.3 407.605,100.133 401.5,98.5C 441.168,87.8335 480.835,87.8335 520.5,98.5 Z"
      />
      <path
        style={{ opacity: 0.986 }}
        fill="#3bb5e2"
        d="M 272.5,192.5 C 269.066,197.716 265.399,202.716 261.5,207.5C 243.034,189.867 225.034,171.7 207.5,153C 194.396,141.077 179.063,136.41 161.5,139C 154.942,140.946 148.942,143.946 143.5,148C 131.333,157.5 120.5,168.333 111,180.5C 97.1361,196.378 80.9695,209.211 62.5,219C 50.0755,225.546 37.4088,231.546 24.5,237C 18.5983,243.665 19.5983,249.332 27.5,254C 30.618,255.893 33.9513,257.226 37.5,258C 89.8791,260.805 131.879,241.472 163.5,200C 167.337,199 169.337,200.5 169.5,204.5C 167.034,215.1 162.2,224.434 155,232.5C 142.501,246.511 127.668,257.178 110.5,264.5C 109.967,261.475 108.133,260.142 105,260.5C 91.5313,265.989 77.6979,270.489 63.5,274C 46.4478,277.467 30.1145,275.467 14.5,268C 0.351971,260.557 -3.14803,249.724 4,235.5C 6.53656,232.628 9.36989,230.128 12.5,228C 40.8092,215.524 66.8092,199.524 90.5,180C 104.863,165.971 118.863,151.638 132.5,137C 162.371,112.814 191.371,113.814 219.5,140C 236.37,158.37 254.036,175.87 272.5,192.5 Z"
      />
      <path
        style={{ opacity: 0.986 }}
        fill="#3ab4e2"
        d="M 1008.5,241.5 C 1008.5,245.5 1008.5,249.5 1008.5,253.5C 1000.28,266.465 988.278,273.632 972.5,275C 961.732,275.928 951.065,275.262 940.5,273C 928.088,269.419 915.921,265.252 904,260.5C 900.867,260.142 899.033,261.475 898.5,264.5C 873.523,254.359 854.69,237.359 842,213.5C 840.86,210.581 840.027,207.581 839.5,204.5C 839.663,200.5 841.663,199 845.5,200C 876.902,241.198 918.569,260.531 970.5,258C 977.874,256.903 983.707,253.403 988,247.5C 989.378,243.058 988.212,239.558 984.5,237C 962.451,228.441 941.784,217.441 922.5,204C 906.8,189.968 892.134,174.968 878.5,159C 869.631,150.065 859.298,143.399 847.5,139C 838.271,136.597 829.271,137.263 820.5,141C 813.832,143.663 807.832,147.329 802.5,152C 782.167,173.667 761.167,194.667 739.5,215C 722.858,229.15 705.525,242.317 687.5,254.5C 684.281,248.729 681.614,242.729 679.5,236.5C 698.637,223.082 717.304,208.915 735.5,194C 756.525,173.974 777.192,153.641 797.5,133C 822.138,115.041 846.805,115.041 871.5,133C 887.113,148.613 902.779,164.279 918.5,180C 943.356,199.938 970.356,216.605 999.5,230C 1003.51,233.161 1006.51,236.994 1008.5,241.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#fefefe"
        d="M 261.5,207.5 C 242.659,236.514 226.993,267.181 214.5,299.5C 214.265,298.903 213.765,298.57 213,298.5C 203.15,302.006 193.316,302.172 183.5,299C 181.382,297.951 179.716,296.451 178.5,294.5C 180.38,292.074 181.713,289.408 182.5,286.5C 199.491,275.181 211.158,259.847 217.5,240.5C 216.671,235.449 214.004,234.282 209.5,237C 186.582,267.793 155.915,283.126 117.5,283C 112.14,281.673 108.474,278.506 106.5,273.5C 108.601,270.873 109.934,267.873 110.5,264.5C 127.668,257.178 142.501,246.511 155,232.5C 162.2,224.434 167.034,215.1 169.5,204.5C 169.337,200.5 167.337,199 163.5,200C 131.879,241.472 89.8791,260.805 37.5,258C 33.9513,257.226 30.618,255.893 27.5,254C 19.5983,249.332 18.5983,243.665 24.5,237C 37.4088,231.546 50.0755,225.546 62.5,219C 80.9695,209.211 97.1361,196.378 111,180.5C 120.5,168.333 131.333,157.5 143.5,148C 148.942,143.946 154.942,140.946 161.5,139C 179.063,136.41 194.396,141.077 207.5,153C 225.034,171.7 243.034,189.867 261.5,207.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#fefefe"
        d="M 898.5,264.5 C 899.023,267.878 900.356,270.878 902.5,273.5C 900.297,278.787 896.297,281.954 890.5,283C 877.365,284.712 864.699,283.045 852.5,278C 831.245,268.884 813.579,255.217 799.5,237C 794.996,234.282 792.329,235.449 791.5,240.5C 794.562,251.29 799.728,260.956 807,269.5C 813.456,276.125 819.956,282.459 826.5,288.5C 827.833,290.5 829.167,292.5 830.5,294.5C 825.698,299.552 819.698,301.885 812.5,301.5C 786.118,299.575 765.118,288.075 749.5,267C 746.165,264.709 743.332,265.209 741,268.5C 741.854,281.874 747.688,292.541 758.5,300.5C 758.167,301.5 757.5,302.167 756.5,302.5C 755.208,310.51 750.541,315.677 742.5,318C 733.395,318.881 724.395,318.381 715.5,316.5C 707.83,295.155 698.497,274.488 687.5,254.5C 705.525,242.317 722.858,229.15 739.5,215C 761.167,194.667 782.167,173.667 802.5,152C 807.832,147.329 813.832,143.663 820.5,141C 829.271,137.263 838.271,136.597 847.5,139C 859.298,143.399 869.631,150.065 878.5,159C 892.134,174.968 906.8,189.968 922.5,204C 941.784,217.441 962.451,228.441 984.5,237C 988.212,239.558 989.378,243.058 988,247.5C 983.707,253.403 977.874,256.903 970.5,258C 918.569,260.531 876.902,241.198 845.5,200C 841.663,199 839.663,200.5 839.5,204.5C 840.027,207.581 840.86,210.581 842,213.5C 854.69,237.359 873.523,254.359 898.5,264.5 Z"
      />
      <path
        style={{ opacity: 0.998 }}
        fill="#fab717"
        d="M 609.5,146.5 C 638.139,172.481 661.472,202.481 679.5,236.5C 681.614,242.729 684.281,248.729 687.5,254.5C 698.497,274.488 707.83,295.155 715.5,316.5C 716.971,321.579 718.637,326.579 720.5,331.5C 727.711,354.049 733.211,377.049 737,400.5C 744.384,420.527 755.051,438.527 769,454.5C 783.707,470.872 798.041,487.539 812,504.5C 823.618,521.404 832.285,539.737 838,559.5C 839.273,581.891 828.773,591.391 806.5,588C 796.625,586.059 788.625,581.059 782.5,573C 773.105,567.8 766.605,570.3 763,580.5C 762.781,606.138 759.281,631.304 752.5,656C 749.357,665.454 745.523,674.62 741,683.5C 740.039,690.291 741.372,696.624 745,702.5C 752.636,711.137 760.636,719.47 769,727.5C 785.6,749.501 790.267,773.835 783,800.5C 773.78,819.857 758.613,827.024 737.5,822C 732.055,820.112 727.055,817.445 722.5,814C 712.53,805.03 702.863,795.697 693.5,786C 676.841,772.869 658.174,768.536 637.5,773C 608.764,785.467 579.098,795.134 548.5,802C 548.043,802.414 547.709,802.914 547.5,803.5C 555.639,829.39 553.472,854.39 541,878.5C 533.761,889.861 523.594,896.861 510.5,899.5C 506.5,899.5 502.5,899.5 498.5,899.5C 483.289,896.146 471.456,887.813 463,874.5C 449.084,845.25 426.917,825.083 396.5,814C 359.965,808.156 325.632,796.156 293.5,778C 254.673,754.213 226.173,721.379 208,679.5C 196.41,652.144 188.077,623.811 183,594.5C 179.928,586.589 174.428,584.089 166.5,587C 159.672,591.915 152.672,596.581 145.5,601C 132.944,606.494 120.277,606.827 107.5,602C 97.2038,594.791 93.3705,584.958 96,572.5C 97.2107,566.868 99.2107,561.534 102,556.5C 119.268,531.632 137.268,507.299 156,483.5C 167.361,467.113 176.361,449.446 183,430.5C 187.999,391.664 196.499,353.664 208.5,316.5C 210.893,310.989 212.893,305.323 214.5,299.5C 226.993,267.181 242.659,236.514 261.5,207.5C 265.399,202.716 269.066,197.716 272.5,192.5C 284.349,175.977 298.016,160.977 313.5,147.5C 412.282,174.471 510.949,174.137 609.5,146.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#3eb6e2"
        d="M 182.5,286.5 C 182.182,284.182 180.848,282.848 178.5,282.5C 161.113,291.073 142.78,296.406 123.5,298.5C 113.5,298.003 104.666,294.67 97,288.5C 96.3333,286.167 96.3333,283.833 97,281.5C 99.7468,278.246 102.914,275.58 106.5,273.5C 108.474,278.506 112.14,281.673 117.5,283C 155.915,283.126 186.582,267.793 209.5,237C 214.004,234.282 216.671,235.449 217.5,240.5C 211.158,259.847 199.491,275.181 182.5,286.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#3eb6e2"
        d="M 902.5,273.5 C 906.086,275.58 909.253,278.246 912,281.5C 913.351,287.844 910.851,292.011 904.5,294C 892.737,299.073 880.737,299.739 868.5,296C 855.509,292.392 842.842,287.892 830.5,282.5C 827.206,283.136 825.873,285.136 826.5,288.5C 819.956,282.459 813.456,276.125 807,269.5C 799.728,260.956 794.562,251.29 791.5,240.5C 792.329,235.449 794.996,234.282 799.5,237C 813.579,255.217 831.245,268.884 852.5,278C 864.699,283.045 877.365,284.712 890.5,283C 896.297,281.954 900.297,278.787 902.5,273.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#3eb5e2"
        d="M 830.5,294.5 C 837.097,299.775 838.097,305.942 833.5,313C 822.22,317.795 810.553,318.795 798.5,316C 787.365,312.433 776.698,307.767 766.5,302C 763.883,301.178 761.216,300.678 758.5,300.5C 747.688,292.541 741.854,281.874 741,268.5C 743.332,265.209 746.165,264.709 749.5,267C 765.118,288.075 786.118,299.575 812.5,301.5C 819.698,301.885 825.698,299.552 830.5,294.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#3fb6e2"
        d="M 178.5,294.5 C 179.716,296.451 181.382,297.951 183.5,299C 193.316,302.172 203.15,302.006 213,298.5C 213.765,298.57 214.265,298.903 214.5,299.5C 212.893,305.323 210.893,310.989 208.5,316.5C 201.195,317.517 193.861,317.683 186.5,317C 180.824,316.416 176.324,313.916 173,309.5C 171.491,303.2 173.324,298.2 178.5,294.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#42b7e3"
        d="M 756.5,302.5 C 758.278,310.345 757.778,318.011 755,325.5C 751.984,329.092 748.151,331.258 743.5,332C 735.818,332.541 728.152,332.374 720.5,331.5C 718.637,326.579 716.971,321.579 715.5,316.5C 724.395,318.381 733.395,318.881 742.5,318C 750.541,315.677 755.208,310.51 756.5,302.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#31302e"
        d="M 292.5,314.5 C 296.463,314.089 300.13,314.922 303.5,317C 319.164,339.997 339.831,345.997 365.5,335C 370.084,332.42 374.084,329.087 377.5,325C 385.42,321.715 391.253,323.881 395,331.5C 395.301,337.908 392.801,343.074 387.5,347C 369.693,360.784 349.693,365.451 327.5,361C 310.119,356.943 296.619,347.443 287,332.5C 282.804,324.578 284.637,318.578 292.5,314.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#302f2e"
        d="M 590.5,313.5 C 601.391,312.888 606.224,317.888 605,328.5C 595.542,346.284 581.042,357.784 561.5,363C 536.209,368.552 514.376,362.385 496,344.5C 491.6,334.969 494.434,328.969 504.5,326.5C 506.923,326.594 509.256,327.094 511.5,328C 523.656,340.606 538.323,344.939 555.5,341C 562.714,338.726 569.381,335.393 575.5,331C 580.173,324.775 585.173,318.942 590.5,313.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#fab217"
        d="M 266.5,373.5 C 307.725,370.519 337.225,387.519 355,424.5C 366.584,458.12 360.418,487.954 336.5,514C 310.067,537.734 280.067,543.401 246.5,531C 209.101,511.591 193.268,480.757 199,438.5C 208.334,402.997 230.834,381.331 266.5,373.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#fab217"
        d="M 620.5,373.5 C 653.244,371.629 679.411,383.629 699,409.5C 721.827,450.337 716.327,486.837 682.5,519C 648.306,543.039 613.639,543.372 578.5,520C 545.808,490.201 538.641,455.367 557,415.5C 571.496,391.34 592.663,377.34 620.5,373.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f9ab19"
        d="M 273.5,380.5 C 307.074,379.728 331.907,395.061 348,426.5C 359.659,461.523 351.492,491.189 323.5,515.5C 290.091,536.972 257.757,534.806 226.5,509C 205.436,484.512 199.769,457.512 209.5,428C 222.398,400.203 243.732,384.37 273.5,380.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f9ab19"
        d="M 621.5,380.5 C 625.586,380.866 629.919,380.866 634.5,380.5C 676.287,386.634 699.953,410.301 705.5,451.5C 705.629,475.112 696.963,495.278 679.5,512C 663.836,523.877 646.669,530.044 628,530.5C 595.637,528.134 572.637,511.801 559,481.5C 547.176,436.81 562.176,404.477 604,384.5C 604.914,385.654 605.747,385.487 606.5,384C 611.944,382.882 616.944,381.716 621.5,380.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f8a519"
        d="M 268.5,386.5 C 310.749,384.268 336.916,403.601 347,444.5C 349.981,481.392 334.481,506.892 300.5,521C 260.951,530.473 232.451,516.973 215,480.5C 204.063,443.591 214.563,414.758 246.5,394C 253.716,390.762 261.049,388.262 268.5,386.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f8a51a"
        d="M 621.5,386.5 C 661.005,384.979 686.505,402.979 698,440.5C 703.209,476.921 689.376,503.088 656.5,519C 618.792,531.1 589.292,520.6 568,487.5C 552.142,449.083 561.309,418.25 595.5,395C 603.86,390.824 612.527,387.99 621.5,386.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f79f1a"
        d="M 269.5,393.5 C 305.142,391.668 328.309,407.668 339,441.5C 343.895,472.194 333.062,495.36 306.5,511C 275.619,523.277 249.452,516.444 228,490.5C 210.546,458.115 215.379,429.615 242.5,405C 250.911,399.484 259.911,395.651 269.5,393.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f79f1a"
        d="M 621.5,393.5 C 650.038,391.849 671.538,403.182 686,427.5C 698.842,459.248 691.675,485.748 664.5,507C 631.72,524.168 603.22,519.002 579,491.5C 563.421,466.595 563.755,441.928 580,417.5C 587.847,408.312 597.347,401.479 608.5,397C 612.999,395.961 617.332,394.794 621.5,393.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f7981c"
        d="M 274.5,398.5 C 310.352,399.688 330.685,418.021 335.5,453.5C 333.909,484.598 317.909,503.765 287.5,511C 264.311,513.277 245.811,505.11 232,486.5C 218.226,460.248 221.06,436.081 240.5,414C 250.527,405.817 261.861,400.651 274.5,398.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f7981c"
        d="M 619.5,399.5 C 643.229,396.958 662.396,404.958 677,423.5C 692.964,452.341 688.797,477.841 664.5,500C 643.157,514.038 621.49,515.038 599.5,503C 578.98,488.784 570.48,469.284 574,444.5C 580.457,420.542 595.624,405.542 619.5,399.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f6921d"
        d="M 270.5,406.5 C 292.28,404.477 309.114,412.477 321,430.5C 332.842,455.608 328.342,477.108 307.5,495C 287.441,508.03 267.774,507.53 248.5,493.5C 228.93,474.791 225.43,453.124 238,428.5C 246.292,417.281 257.126,409.947 270.5,406.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f6921d"
        d="M 623.5,406.5 C 654.92,406.096 673.587,421.262 679.5,452C 679.178,478.288 666.011,495.455 640,503.5C 609.366,506.556 589.699,493.223 581,463.5C 579.504,432.531 593.671,413.531 623.5,406.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f5891e"
        d="M 275.5,411.5 C 302.942,412.942 318.609,427.275 322.5,454.5C 321.732,471.216 314.398,484.05 300.5,493C 279.473,502.728 260.973,499.228 245,482.5C 233.116,464.373 233.116,446.373 245,428.5C 253.001,418.754 263.168,413.087 275.5,411.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f5891e"
        d="M 620.5,412.5 C 647.544,410.069 665.044,421.736 673,447.5C 675.613,472.066 665.113,488.566 641.5,497C 613.376,501.7 595.209,490.534 587,463.5C 584.463,437.248 595.63,420.248 620.5,412.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f4811f"
        d="M 271.5,422.5 C 281.426,421.39 290.76,423.557 299.5,429C 303.196,432.036 306.196,435.703 308.5,440C 308.167,440.333 307.833,440.667 307.5,441C 312.03,447.297 313.03,453.963 310.5,461C 310.833,461.333 311.167,461.667 311.5,462C 306.164,479.534 294.498,488.367 276.5,488.5C 262.907,486.306 253.407,478.972 248,466.5C 243.326,444.955 251.159,430.288 271.5,422.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#f4811f"
        d="M 623.5,422.5 C 628.515,422.15 633.348,422.817 638,424.5C 638.414,424.043 638.914,423.709 639.5,423.5C 643.3,425.247 647.3,427.413 651.5,430C 664.944,443.3 666.944,457.967 657.5,474C 654.422,477.282 651.255,480.449 648,483.5C 645.013,484.248 641.847,485.581 638.5,487.5C 616.774,490.137 602.774,480.97 596.5,460C 596.551,456.486 596.551,452.986 596.5,449.5C 597.5,446.833 598.5,444.167 599.5,441.5C 600.025,440.808 600.692,440.308 601.5,440C 600.549,439.282 600.383,438.449 601,437.5C 605.439,432.911 609.772,428.911 614,425.5C 614.333,425.833 614.667,426.167 615,426.5C 617.168,424.545 619.502,423.879 622,424.5C 622.717,423.956 623.217,423.289 623.5,422.5 Z"
      />
      <path
        style={{ opacity: 1 }}
        fill="#31302e"
        d="M 386.5,427.5 C 389.941,427.301 393.274,427.801 396.5,429C 430.684,457.859 464.684,457.525 498.5,428C 508.443,426.947 512.609,431.447 511,441.5C 507.993,448.841 503.16,454.674 496.5,459C 483.342,468.164 468.675,473.164 452.5,474C 426.921,476.254 405.088,468.421 387,450.5C 383.155,445.379 381.489,439.713 382,433.5C 383.332,431.34 384.832,429.34 386.5,427.5 Z"
      />
    </Svg>
  );
};

export default Icon;
