import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | ByteExchange',
  defaultTitle: 'ByteExchange',
  description:
    'Cheaper and faster than Uniswap? Discover ByteExchange, the best intelligent DEX with the best farms in DeFi and a lottery for BYTE.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@ByteExchange',
    site: '@ByteExchange',
  },
  openGraph: {
    title: '👼 ByteExchange - A next intelligent DeFi exchange',
    description:
      'The most popular AMM by user count! Earn BYTE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by ByteExchange), NFTs, and more, on a platform you can trust.',
    images: [{ url: 'https://assets.byte.exchange/web/og/hero.jpg' }],
  },
}
